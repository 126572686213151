import React, { Component, useRef } from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isMainSearchTypeKeywords } from '../../util/search';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { Page, LayoutSingleColumn, H3, H1, H2, NamedLink } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import classNames from 'classnames';
import { BecomeProviderPageData as data } from './data';

const { SECTION_HERO, SECTION_HIGHLIGHTS, SECTION_PROCESS, SECTION_CALL_TO_ACTION } = data;
const { IconScroll } = SECTION_HERO;
const { IconArrow } = SECTION_PROCESS;

import css from './BecomeProviderPage.module.css';
import { getProfilePageIdParam } from '../../util/userHelpers';

export const BecomeProviderPageComponent = props => {
  const { intl, scrollingDisabled, currentUser } = props;

  const sectionToScroll = useRef();

  const scrollToSection = () => {
    sectionToScroll.current.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  return (
    <Page
      title={intl.formatMessage({ id: 'BecomeProviderPage.title' })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn
        className={css.root}
        topbar={<TopbarContainer />}
        footer={<FooterContainer />}
      >
        <div className={css.content}>
          <section className={classNames(css.section, css.sectionHorizontal)}>
            <div className={css.heroImageWrapper}>
              <img src={SECTION_HERO.imageSrc} alt="RogueRunner" />
            </div>
            <div className={css.heroContent}>
              <H1>{SECTION_HERO.title}</H1>
              <p>
                {SECTION_HERO.description.map((d, i) => (
                  <span key={`description_${i}`}>{d}</span>
                ))}
              </p>
            </div>
            <a className={css.heroScroll} onClick={scrollToSection}>
              <span>{SECTION_HERO.ctaText}</span>
              <IconScroll />
            </a>
          </section>
          <section ref={sectionToScroll} className={classNames(css.section, css.sectionExtended)}>
            <div className={css.highlights}>
              {SECTION_HIGHLIGHTS.map(({ Icon, title, description }) => (
                <div key={title}>
                  <Icon />
                  <H3>{title}</H3>
                  <p>{description}</p>
                </div>
              ))}
            </div>
          </section>
          <section className={css.section}>
            <H2>{SECTION_PROCESS.title}</H2>
            <div className={css.process}>
              {SECTION_PROCESS.steps.map(({ Icon, title, description }) => (
                <div key={title} className={css.step}>
                  <div className={css.card}>
                    <Icon />
                    <h3>{title}</h3>
                    <p>{description}</p>
                  </div>
                  <IconArrow />
                </div>
              ))}
            </div>
          </section>
          <section className={css.section}>
            <H3>{SECTION_CALL_TO_ACTION.title}</H3>
            <div className={css.callToAction}>
              {currentUser ? (
                <NamedLink
                  className={css.submitButton}
                  name="ProfilePage"
                  params={{ id: getProfilePageIdParam(currentUser) }}
                >
                  {SECTION_CALL_TO_ACTION.ctaText}
                </NamedLink>
              ) : (
                <NamedLink className={css.submitButton} name="SignupPage">
                  {SECTION_CALL_TO_ACTION.ctaText}
                </NamedLink>
              )}
            </div>
          </section>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

BecomeProviderPageComponent.defaultProps = {
  staticContext: {},
};

BecomeProviderPageComponent.propTypes = {
  // from useIntl
  intl: intlShape.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const EnhancedBecomeProviderPage = props => {
  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();
  const history = useHistory();
  const intl = useIntl();
  const { currentUser } = useSelector(state => state.user);

  return (
    <BecomeProviderPageComponent
      routeConfiguration={routeConfiguration}
      marketplaceName={config.marketplaceName}
      isKeywordSearch={isMainSearchTypeKeywords(config)}
      history={history}
      currentUser={currentUser}
      intl={intl}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const BecomeProviderPage = compose(connect(mapStateToProps))(EnhancedBecomeProviderPage);

export default BecomeProviderPage;
