import classNames from 'classnames';
import { string } from 'prop-types';
import React, { useMemo } from 'react';
import NamedLink from '../NamedLink/NamedLink';
import { stringify } from '../../util/urlHelpers';
import { LISTING_CATEGORY_DESCRIPTION } from '../../config/configListing';

import css from './CategoriesPath.module.css';

const CategoriesPath = props => {
  const { rootClassName, className, categoryLevel1, categoryLevel2 } = props;

  const category1 = useMemo(
    () => LISTING_CATEGORY_DESCRIPTION.find(c => c.id === categoryLevel1)?.label,
    [categoryLevel1]
  );
  const category2 = useMemo(
    () => LISTING_CATEGORY_DESCRIPTION.find(c => c.id === categoryLevel2)?.label,
    [categoryLevel2]
  );

  return (
    <div className={classNames(rootClassName || css.root, className)}>
      {category1 ? (
        <>
          <NamedLink
            name="SearchPage"
            to={{ search: stringify({ pub_categoryLevel1: categoryLevel1 }) }}
          >
            {category1}
          </NamedLink>
          {category2 ? <span>{' > '}</span> : null}
        </>
      ) : null}
      {category2 ? (
        <NamedLink
          name="SearchPage"
          to={{
            search: stringify({
              pub_categoryLevel1: categoryLevel1,
              pub_categoryLevel2: categoryLevel2,
            }),
          }}
        >
          {category2}
        </NamedLink>
      ) : null}
    </div>
  );
};

CategoriesPath.defaultProps = {
  className: null,
};

CategoriesPath.propTypes = {
  className: string,
};

export default CategoriesPath;
