import imageBearSrc from '../../assets/become-a-provider-bear.png';

const SECTION_HERO = {
  imageSrc: imageBearSrc,
  title: 'Becoming a Rogue Runner: 101',
  description: [
    'Your tasking lore begins here.',
    'From laid-back tasks from the comfort of your home to high-energy in person missions, the path is yours to create.',
  ],
  ctaText: 'SCROLL',
  IconScroll: () => (
    <svg width="12" height="28" viewBox="0 0 16 28" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.46967 27.5303C5.76256 27.8232 6.23744 27.8232 6.53033 27.5303L11.3033 22.7574C11.5962 22.4645 11.5962 21.9896 11.3033 21.6967C11.0104 21.4038 10.5355 21.4038 10.2426 21.6967L6 25.9393L1.75736 21.6967C1.46446 21.4038 0.989591 21.4038 0.696698 21.6967C0.403804 21.9896 0.403805 22.4645 0.696698 22.7574L5.46967 27.5303ZM5.24999 2.137e-07L5.25 27L6.75 27L6.74999 -2.137e-07L5.24999 2.137e-07Z" />
    </svg>
  ),
};

const SECTION_HIGHLIGHTS = [
  {
    title: 'Set Your Own Schedule',
    description:
      'Whether you prefer morning tasks, late-night gigs, or only weekends, the choice is yours. Adjust your availability each week and opt-in to same-day jobs whenever youre ready to jump in.',
    Icon: () => (
      <svg width="46" height="46" viewBox="0 0 46 46" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.18357 6.77536C4.33922 6.77536 3.52948 7.11078 2.93245 7.7078C2.33541 8.30484 2 9.11459 2 9.95893V40.2029C2 41.0471 2.33541 41.857 2.93245 42.454C3.52948 43.0509 4.33922 43.3864 5.18357 43.3864H40.2029C41.0471 43.3864 41.857 43.0509 42.454 42.454C43.0509 41.857 43.3864 41.0471 43.3864 40.2029V9.95893C43.3864 9.11459 43.0509 8.30484 42.454 7.7078C41.857 7.11078 41.0471 6.77536 40.2029 6.77536H33.8357" />
        <path d="M11.5507 2V11.5507" />
        <path d="M33.8357 2V11.5507" />
        <path d="M11.5507 6.77536H27.4685" />
        <path d="M23.4851 15.2195L26.1485 20.5773C26.2081 20.7128 26.3025 20.8301 26.4222 20.9171C26.5419 21.0042 26.6826 21.0579 26.8298 21.0728L32.7451 21.971C32.9141 21.993 33.0736 22.0627 33.2045 22.1719C33.3356 22.2812 33.4327 22.4254 33.4846 22.5878C33.5365 22.7503 33.5413 22.9242 33.498 23.0891C33.455 23.2541 33.3656 23.4034 33.2408 23.5195L28.8739 27.6695C28.8088 27.7936 28.7748 27.9318 28.7748 28.0721C28.7748 28.2123 28.8088 28.3505 28.8739 28.4747L29.7101 34.3591C29.7457 34.5291 29.7313 34.7058 29.6685 34.8678C29.6057 35.0299 29.4973 35.1703 29.3563 35.2718C29.2153 35.3734 29.0479 35.432 28.8743 35.4406C28.7007 35.4488 28.5284 35.4065 28.3784 35.3189L23.1134 32.5317C22.9772 32.4703 22.8294 32.4384 22.6799 32.4384C22.5303 32.4384 22.3826 32.4703 22.2463 32.5317L16.9814 35.3189C16.8313 35.4065 16.659 35.4488 16.4854 35.4406C16.3118 35.432 16.1444 35.3734 16.0034 35.2718C15.8625 35.1703 15.754 35.0299 15.6912 34.8678C15.6284 34.7058 15.614 34.5291 15.6497 34.3591L16.6407 28.4747C16.6833 28.3381 16.6915 28.1931 16.6645 28.0526C16.6375 27.9121 16.5761 27.7805 16.4859 27.6695L12.1191 23.4885C12.0033 23.3712 11.9219 23.2242 11.8841 23.0638C11.8462 22.9033 11.8533 22.7355 11.9045 22.5787C11.9557 22.422 12.049 22.2824 12.1742 22.1752C12.2995 22.068 12.4519 21.9973 12.6146 21.971L18.5299 21.1038C18.6771 21.0889 18.8178 21.0352 18.9375 20.9481C19.0572 20.8611 19.1517 20.7438 19.2112 20.6083L21.8747 15.2505C21.9459 15.0988 22.0582 14.9701 22.1988 14.8789C22.3394 14.7877 22.5027 14.7377 22.6703 14.7344C22.8378 14.7312 23.0029 14.7749 23.1469 14.8607C23.2909 14.9464 23.408 15.0707 23.4851 15.2195Z" />
      </svg>
    ),
  },
  {
    title: 'Choose How You Work',
    description:
      'Take on tasks that suit your lifestyle. Whether you prefer working from home or getting out in the field, we offer both remote and in-person opportunities across multiple categories.',
    Icon: () => (
      <svg width="40" height="45" viewBox="0 0 40 45" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.54367 33.7968C10.5505 33.7968 12.1773 32.17 12.1773 30.1633C12.1773 28.1565 10.5505 26.5297 8.54367 26.5297C6.53687 26.5297 4.91003 28.1565 4.91003 30.1633C4.91003 32.17 6.53687 33.7968 8.54367 33.7968Z" />
        <path d="M2 43.1756C2.01156 42.0677 2.30374 40.9809 2.84922 40.0166C3.99871 37.9845 6.20639 36.6942 8.54052 36.6891C10.8747 36.6942 13.0823 37.9845 14.2318 40.0166C14.7773 40.9809 15.0695 42.0677 15.081 43.1756" />
        <path d="M30.9716 33.7968C32.9786 33.7968 34.6054 32.17 34.6054 30.1633C34.6054 28.1565 32.9786 26.5297 30.9716 26.5297C28.9648 26.5297 27.338 28.1565 27.338 30.1633C27.338 32.17 28.9648 33.7968 30.9716 33.7968Z" />
        <path d="M24.428 43.1756C24.4396 42.0677 24.7317 40.9809 25.2772 40.0166C26.4267 37.9845 28.6344 36.6942 30.9684 36.6891C33.3026 36.6942 35.5104 37.9845 36.6597 40.0166C37.2053 40.9809 37.4976 42.0677 37.5091 43.1756" />
        <path d="M19.7451 13.1494C21.6183 13.1494 23.1369 11.6308 23.1369 9.75756C23.1369 7.88432 21.6183 6.36575 19.7451 6.36575C17.8718 6.36575 16.3533 7.88432 16.3533 9.75756C16.3533 11.6308 17.8718 13.1494 19.7451 13.1494Z" />
        <path d="M13.406 21.6533C13.4172 20.5791 13.7005 19.5253 14.2294 18.5903C15.344 16.62 17.4845 15.3688 19.7477 15.3639C22.0109 15.3688 24.1515 16.62 25.266 18.5903C25.7949 19.5253 26.0782 20.5791 26.0894 21.6533" />
        <path d="M34.9715 2H4.52518C3.26236 2 2.23865 3.02372 2.23865 4.28653V19.3666C2.23865 20.6295 3.26236 21.6532 4.52518 21.6532H34.9715C36.2341 21.6532 37.2579 20.6295 37.2579 19.3666V4.28653C37.2579 3.02372 36.2341 2 34.9715 2Z" />
      </svg>
    ),
  },
  {
    title: 'Curate Your Services',
    description:
      'Pick tasks that match your strengths, or try something new to keep things interesting. With many categories to choose from, you can build a portfolio that fits your style and evolves with you.',
    Icon: () => (
      <svg width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6863 25.9763C16.9555 25.9763 19.6058 23.326 19.6058 20.0568C19.6058 16.7876 16.9555 14.1374 13.6863 14.1374C10.4171 14.1374 7.76685 16.7876 7.76685 20.0568C7.76685 23.326 10.4171 25.9763 13.6863 25.9763Z" />
        <path d="M2 43.119C2.02068 41.1391 2.54286 39.1965 3.51777 37.4733C5.57226 33.8411 9.51801 31.5348 13.6897 31.5257C17.8615 31.5348 21.8072 33.8411 23.8617 37.4733C24.8366 39.1965 25.3588 41.1391 25.3795 43.119" />
        <path d="M31.8086 4.61241V2" />
        <path d="M40.7197 7.81856L42.8578 6.31757" />
        <path d="M22.8914 7.81856L20.7532 6.31757" />
        <path d="M38.2846 16.3131C38.298 12.7235 35.2599 9.73656 31.6709 9.81109C28.0819 9.88565 25.1704 12.9961 25.3329 16.5821C25.4375 18.8903 26.8492 21.0589 28.9175 22.0888V25.2728C28.9223 25.4611 29.0005 25.64 29.1354 25.7714C29.2703 25.9028 29.4512 25.9763 29.6395 25.9763H33.9712C34.1596 25.9763 34.3405 25.9028 34.4755 25.7714C34.6101 25.64 34.6884 25.4611 34.6932 25.2728V22.0333C36.8265 20.9712 38.255 18.6961 38.2846 16.3131Z" />
      </svg>
    ),
  },
];

// Section Process
const SECTION_PROCESS = {
  title: 'Our process',
  IconArrow: () => (
    <svg viewBox="0 0 391 97" xmlns="http://www.w3.org/2000/svg">
      <path d="M383.5 96.5L390.717 84H376.283L383.5 96.5ZM0 2.75H7.2375V0.25H0V2.75ZM21.7125 2.75H36.1875V0.25H21.7125V2.75ZM50.6625 2.75H65.1375V0.25H50.6625V2.75ZM79.6125 2.75H94.0875V0.25H79.6125V2.75ZM108.562 2.75H123.037V0.25H108.562V2.75ZM137.512 2.75H151.988V0.25H137.512V2.75ZM166.463 2.75H180.938V0.25H166.463V2.75ZM195.413 2.75H209.888V0.25H195.413V2.75ZM224.363 2.75H238.838V0.25H224.363V2.75ZM253.313 2.75H267.788V0.25H253.313V2.75ZM282.263 2.75H289.5V0.25H282.263V2.75ZM289.5 2.75C291.952 2.75 294.382 2.84513 296.786 3.03188L296.979 0.539387C294.511 0.347644 292.017 0.25 289.5 0.25V2.75ZM311.163 5.29307C315.93 6.43364 320.554 7.94232 325.003 9.78743L325.961 7.47811C321.391 5.58292 316.641 4.03324 311.744 2.86169L311.163 5.29307ZM337.966 16.4049C342.117 18.9536 346.052 21.82 349.737 24.9702L351.361 23.07C347.577 19.8354 343.537 16.8919 339.275 14.2745L337.966 16.4049ZM360.03 35.2634C363.18 38.9484 366.046 42.8831 368.595 47.0335L370.725 45.7253C368.108 41.463 365.165 37.4226 361.93 33.6389L360.03 35.2634ZM375.213 59.9968C377.058 64.4462 378.566 69.0703 379.707 73.8375L382.138 73.2558C380.967 68.3591 379.417 63.6093 377.522 59.0391L375.213 59.9968ZM381.968 88.2144C382.155 90.6181 382.25 93.0478 382.25 95.5H384.75C384.75 92.9831 384.652 90.4889 384.461 88.0207L381.968 88.2144ZM382.25 95.5V95.75H384.75V95.5H382.25Z" />
    </svg>
  ),
  steps: [
    {
      title: '1. Get in the Door',
      description: 'Sign up on our website to create your account and start your registration.',
      Icon: () => (
        <svg width="42" height="42" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 21C2 23.4951 2.49145 25.9658 3.44629 28.271C4.40113 30.5762 5.80066 32.6707 7.56497 34.435C9.32928 36.1993 11.4238 37.5989 13.729 38.5537C16.0342 39.5086 18.5049 40 21 40C23.4951 40 25.9658 39.5086 28.271 38.5537C30.5762 37.5989 32.6707 36.1993 34.435 34.435C36.1993 32.6707 37.5989 30.5762 38.5537 28.271C39.5086 25.9658 40 23.4951 40 21C40 18.5049 39.5086 16.0342 38.5537 13.729C37.5989 11.4238 36.1993 9.32928 34.435 7.56497C32.6707 5.80066 30.5762 4.40113 28.271 3.44629C25.9658 2.49145 23.4951 2 21 2C18.5049 2 16.0342 2.49145 13.729 3.44629C11.4238 4.40113 9.32928 5.80066 7.56497 7.56497C5.80066 9.32928 4.40113 11.4238 3.44629 13.729C2.49145 16.0342 2 18.5049 2 21Z" />
          <path d="M16.5189 12.4441C16.5189 13.0292 16.6341 13.6084 16.858 14.1489C17.0819 14.6894 17.41 15.1805 17.8237 15.5941C18.2374 16.0078 18.7284 16.3359 19.2689 16.5598C19.8094 16.7837 20.3887 16.8989 20.9737 16.8989C21.5587 16.8989 22.138 16.7837 22.6785 16.5598C23.2189 16.3359 23.71 16.0078 24.1237 15.5941C24.5374 15.1805 24.8655 14.6894 25.0894 14.1489C25.3132 13.6084 25.4285 13.0292 25.4285 12.4441C25.4285 11.8591 25.3132 11.2799 25.0894 10.7394C24.8655 10.1989 24.5374 9.70781 24.1237 9.29415C23.71 8.88049 23.2189 8.55235 22.6785 8.32848C22.138 8.10461 21.5587 7.98938 20.9737 7.98938C20.3887 7.98938 19.8094 8.10461 19.2689 8.32848C18.7284 8.55235 18.2374 8.88049 17.8237 9.29415C17.41 9.70781 17.0819 10.1989 16.858 10.7394C16.6341 11.2799 16.5189 11.8591 16.5189 12.4441Z" />
          <path d="M24.8032 26.4068L23.8525 34.0127H18.1471L17.1964 26.4068H15.2954C14.7703 26.4068 14.3446 25.9811 14.3446 25.4561V23.5546C14.3446 19.879 17.324 16.8994 20.9996 16.8994C24.6751 16.8994 27.655 19.879 27.655 23.5546V25.4561C27.655 25.9811 27.2293 26.4068 26.7043 26.4068H24.8032Z" />
        </svg>
      ),
    },
    {
      title: '2. Map Your Moves',
      description:
        'Choose the services you want to offer and decide whether you’ll take remote tasks, in-person gigs, or both. Your path is yours to design.',
      Icon: () => (
        <svg width="42" height="42" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
          <path d="M40 23.9231V31.2308C40 31.6184 39.846 31.9902 39.5721 32.2644C39.2979 32.5383 38.9261 32.6923 38.5385 32.6923H3.46154C3.07392 32.6923 2.70217 32.5383 2.42808 32.2644C2.15398 31.9902 2 31.6184 2 31.2308V7.84618C2 7.45855 2.15398 7.0868 2.42808 6.81273C2.70217 6.53863 3.07392 6.38464 3.46154 6.38464H9.30769" />
          <path d="M18.0769 32.6923L15.1538 40" />
          <path d="M23.9231 32.6923L26.8462 40" />
          <path d="M12.2307 40H29.7692" />
          <path d="M16.6155 16.6154H25.3847V22.4615L34.1539 16.6154H40.0001V2H16.6155V16.6154Z" />
        </svg>
      ),
    },
    {
      title: '3. Show Us You’re ready',
      description:
        'Verify your identity and complete any required checks; we’ll also review your social media—discretion and alignment with our standards are a  must (18+ to apply, 21+ for select listings).',
      Icon: () => (
        <svg width="46" height="34" viewBox="0 0 46 34" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.7315 2H40.6549C40.6549 2 43.3864 2 43.3864 4.7315V29.4105C43.3864 29.4105 43.3864 32.1421 40.6549 32.1421H4.7315C4.7315 32.1421 2 32.1421 2 29.4105V4.7315C2 4.7315 2 2 4.7315 2Z" />
          <path d="M30.2045 13.5035H36.5296" />
          <path d="M30.2045 19.7723H36.5296" />
          <path d="M11.5736 14.5487C11.5736 17.4336 13.9123 19.7723 16.7972 19.7723C17.5265 19.7723 18.2209 19.6228 18.8515 19.3528C20.7151 18.5549 22.0208 16.7043 22.0208 14.5487C22.0208 11.6638 19.6821 9.32507 16.7972 9.32507C13.9123 9.32507 11.5736 11.6638 11.5736 14.5487Z" />
          <path d="M8.85681 24.9449C9.68683 23.3662 10.8534 22.0614 12.2443 21.156C13.6353 20.2506 15.2038 19.7751 16.7992 19.7751C18.3945 19.7751 19.9631 20.2506 21.354 21.156C22.745 22.0614 23.9115 23.3662 24.7415 24.9449" />
        </svg>
      ),
    },
    {
      title: '4. Wait for the Green Light',
      description:
        'Once your application is in, we’ll review it and get back to you within 48 hours. Applications open and close periodically, so keep an eye on our social media and newsletter for the next opportunity to become a runner.',
      Icon: () => (
        <svg width="42" height="42" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.1539 16.6154C19.1898 16.6154 22.4616 13.3436 22.4616 9.30769C22.4616 5.27177 19.1898 2 15.1539 2C11.118 2 7.84619 5.27177 7.84619 9.30769C7.84619 13.3436 11.118 16.6154 15.1539 16.6154Z" />
          <path d="M15.1538 37.077H2V35.4924C2.02327 33.2645 2.61086 31.0786 3.70787 29.1393C4.8049 27.2 6.37555 25.5703 8.27313 24.4026C10.1707 23.2348 12.3333 22.5671 14.5589 22.4617C14.7574 22.4523 14.9557 22.4474 15.1538 22.447C15.352 22.4474 15.5504 22.4523 15.7488 22.4617C17.9744 22.5671 20.137 23.2348 22.0346 24.4026C23.1258 25.0741 24.1088 25.8983 24.9559 26.8463" />
          <path d="M39.9999 25.3845L28.3076 39.9999L22.4614 35.6153" />
        </svg>
      ),
    },
    {
      title: '5. Hit the Ground Running',
      description:
        'If approved, you’ll be ready to start accepting jobs and building your  reputation. Keep things tight—Runners need to maintain a 4-star average  to stay active.',
      Icon: () => (
        <svg width="42" height="42" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
          <path d="M34.1539 15.1538C34.1539 23.9231 21 34.1538 21 34.1538C21 34.1538 7.84619 23.9231 7.84619 15.1538C7.84619 7.98938 13.8356 2 21 2C28.1645 2 34.1539 7.98938 34.1539 15.1538Z" />
          <path d="M21 19.5385C23.4215 19.5385 25.3846 17.5755 25.3846 15.1539C25.3846 12.7323 23.4215 10.7693 21 10.7693C18.5784 10.7693 16.6154 12.7323 16.6154 15.1539C16.6154 17.5755 18.5784 19.5385 21 19.5385Z" />
          <path d="M32.9183 29.7693H35.6154L40 40.0001H2L6.38462 29.7693H9.08174" />
        </svg>
      ),
    },
    {
      title: '6. Make the Platform Yours',
      description:
        'You’re not just using the platform—you’re shaping it. Add what’s missing, test ideas, and carve out your place here.',
      Icon: () => (
        <svg width="42" height="42" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
          <path d="M31.2308 2H10.7692C5.92613 2 2 5.92613 2 10.7692V31.2308C2 36.074 5.92613 40 10.7692 40H31.2308C36.074 40 40 36.074 40 31.2308V10.7692C40 5.92613 36.074 2 31.2308 2Z" />
          <path d="M29.527 14.4231L17.8347 29.0385L11.9885 24.6539" />
        </svg>
      ),
    },
  ],
};

const SECTION_CALL_TO_ACTION = {
  title: 'Time to make your move.',
  ctaText: 'Apply Now!',
};

export const BecomeProviderPageData = {
  SECTION_HERO,
  SECTION_HIGHLIGHTS,
  SECTION_PROCESS,
  SECTION_CALL_TO_ACTION,
};
