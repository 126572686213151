import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import IconInfo from '../IconInfo/IconInfo';

import css from './Tooltip.module.css';

export function useInvalidateUI() {
  const [invalidate, setInvalidate] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  const timeout = useRef();

  useEffect(() => {
    const toggle = () => {
      clearTimeout(timeout.current);
      setInvalidate(old => !old);
      setIsResizing(true);
      timeout.current = setTimeout(() => setIsResizing(false), 200);
    };

    window.addEventListener('resize', toggle);

    return () => {
      return window.removeEventListener('resize', toggle);
    };
  });

  return { invalidate, isResizing };
}

const ToolTip = props => {
  const {
    id,
    className,
    rootClassName,
    triggerIcon = IconInfo,
    triggerEl,
    children,
    ...rest
  } = props;

  useInvalidateUI();

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;
  const Icon = triggerIcon;

  return (
    <span className={classNames(rootClassName || css.root, className)}>
      <a data-tooltip-id={id}>{triggerEl ? triggerEl : <Icon />}</a>
      <Tooltip
        id={id}
        place="bottom-start"
        className={css.tooltip}
        openOnClick={isMobile}
        delayShow={50}
        delayHide={100}
        clickable
        {...rest}
      >
        {children}
      </Tooltip>
    </span>
  );
};

export default ToolTip;
